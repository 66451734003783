import React from 'react';

const SVG = ({
  style = {},
  fill = 'none',
  width = '100%',
  height = '100%',
  className = 'inline-block fill-current h-6 w-6',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <rect fill={fill} />
    </g>
    <g>
      <path d="M6,2l0.01,6L10,12l-3.99,4.01L6,22h12v-6l-4-4l4-3.99V2H6z M16,16.5V20H8v-3.5l4-4L16,16.5z" />
    </g>
  </svg>
);

export default SVG;
