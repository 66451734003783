import React from 'react';
import { TurSystemIcon } from '../img/icons';

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="flex flex-col-reverse md:flex-row items-center max-w-full p-6 py-8 md:pl-12">
        <div className="flex items-center">
          <div className="text-xs text-disabled-on-surface-0">Built by</div>
          <a
            className="no-underline font-medium ml-1"
            target="_blank"
            href="https://turanszky.com/"
          >
            <TurSystemIcon className="fill-current text-medium-on-surface-0 h-8 w-8 md:h-6 md:w-6" />
          </a>
        </div>
        {/* 
        <div className="flex items-center">
          <a
            className="no-underline p-6"
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/STuranszky"
          >
            <TwitterIcon className="fill-current space-x-0h-8 w-8 md:h-6 md:w-6" />
          </a>

          <a
            className="no-underline p-6 md:pl-4"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/sandor-turanszky-ab716447/"
          >
            <LinkedInIcon className="fill-current h-8 w-8 md:h-6 md:w-6" />
          </a>
          <a
            className="no-underline p-6 md:pl-4 md:pr-0"
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/sandorTuranszky"
          >
            <GitHubIcon className="fill-current h-8 w-8 md:h-6 md:w-6" />
          </a>
        </div> */}
      </footer>
    );
  }
};

export default Footer;
